import { useState } from "react";
import { NavLink } from "../templates/NavLink";

import logoIPN from "../images/IPN-logo.png";
import logoCIC from "../images/cic-logo.png";
import logoLab from "../images/logoLab.png";

export const Menu_Lab = () => {
    return (
      <>
      <div>
        <h5>&nbsp;</h5>
      </div>
        <nav class="navbar navbar-inverse sub-navbar navbar-fixed-top">
        <div class="container">
            <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#subenlaces">
                <span class="sr-only">Interruptor de Navegación</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
                <a href="https://www.ipn.mx">
                <img src={logoIPN} alt="logo IPN" height="50px"/>
                </a> &nbsp;
                <a href="https://www.cic.ipn.mx/">
                <img src={logoCIC} alt="logo CIC"  height="50px"/>
                </a>&nbsp;
                <a href="#">
                <img src={logoLab} alt="logo Laboratorio"  height="50px"/>
                </a>
            </div>
            <div class="collapse navbar-collapse" id="subenlaces">
            <ul class="nav navbar-nav navbar-right">
                {/**Inicio**/}
                <li><a href="#">Inicio</a></li>
                {/**Alumnos**/}
                <li class="dropdown">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">Alumnos <span class="caret"></span></a>
                <ul class="dropdown-menu" role="menu">
                <li><NavLink to="/Alumnos25A">
                            Semestre 25A, enero-julio 2025
                            </NavLink></li>
                    <li><NavLink to="/Alumnos24B">
                            Semestre 24B, agosto-diciembre 2024
                            </NavLink></li>
                    <li><NavLink to="/Alumnos24A">
                            Semestre 24A, enero-julio 2024
                            </NavLink></li>
                    <li><NavLink to="/Alumnos23B">
                            Semestre 23B, agosto-diciembre 2023
                            </NavLink></li>
                    <li><NavLink to="/Alumnos23A">
                        Semestre 23A, enero-julio 2023
                        </NavLink></li>
                    <li class="divider"></li>
                    <li><NavLink to="/estancias">Estancias</NavLink></li>
                    <li class="divider"></li>
                    <li><NavLink to="/Infraestructura">Infraestructura</NavLink></li>
                    <li class="divider"></li>
                    <li><NavLink to="/Proyectos">Proyectos de Innovación</NavLink></li>
                    <li class="divider"></li>
                    <li><NavLink to="/Calendario">Calendario Seminario</NavLink></li>
                    <li><NavLink to="/SeminarioMetodolo">Seminario Metodología Investigación</NavLink></li>
                    <li class="divider"></li>
                    <li><NavLink to="/Publicaciones">Publicaciones acerca del LRyM</NavLink></li>
                </ul>
                </li>
                {/**/}
                <li><NavLink to="/tesis">Propuestas de Tesis</NavLink></li>
                {/**/}
                <li class="dropdown">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">Investigación<span class="caret"></span></a>
                <ul class="dropdown-menu" role="menu">
                    <li><NavLink to="/investigacion">
                        Post-doctoral
                        </NavLink></li>
                    <li class="divider"></li>
                    <strong>Bases de Datos</strong>
                    <li  class="dropdown">
                            <NavLink to="Espectros">
                                Espectros
                            </NavLink>
                    </li>
                    
                    {/*<li><a href="#">Algo más aquí</a></li>
                    <li><a href="#">Enlace separado</a></li>*/}
                </ul>
                </li>
                {/**/}
                <li class="dropdown">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">Eventos<span class="caret"></span></a>
                <ul class="dropdown-menu" role="menu">
                        <li> <NavLink to="/EIR2024">Escuela de Invierno de Robótica 2024</NavLink> </li>
                    <li> <a href="https://sites.google.com/view/qiskit-fallfest-cdmx24/inicio" >Qiskit Fall Fest 2024</a> </li>
                    <li> <a href="https://indico.buap.mx/event/97/" >Oportunidades en la Computación Quántica 2024</a></li>
                    <li>  <NavLink to="/Qiskit2023">Qiskit Fall Fest 2023</NavLink> </li>
                    <li> <a href="https://proyectosrym.cic.ipn.mx/qcoloquio/" >3er Coloquio de cómputo cuántico</a> </li>
                </ul>
                </li>
                {/**/}
                <li>
                  <NavLink to="/galeria">Galería</NavLink>
                </li>
                {/*Fin*/}
            </ul>
            </div>
        </div>
        </nav>
        </>
);
};
