
import foto from "../images/Personal_AlfonsoR/AlfonsoR.jpg";

export const AlfonsoR = () => {
  return (
    <>
    <h1 onLoad={window.scrollTo(0, 0)}>&nbsp;</h1>
    <div className="col-lg-1"></div>
    <div className="col-lg-10">
    <h3>Dr. Alfonso Rojas Domínguez</h3>
    <h6>Profesor invitado, Programa Investigadores por México, SECIHTI</h6>
          <hr class="red"/> 
          <p align="right"><a href="https://sites.google.com/view/alfonsorojas" target="new">Más información</a></p>
          <div className="col-lg-3"><img src={foto} width="100%" alt="Foto Dr. Alfonso Rojas"></img></div>
          <div className="col-lg-9">
            <p><strong>Formación Académica</strong></p>
            <p><ul>
                <ol>Ing. Telecomunicaciones</ol>
                <ol>M. en C. de la Computación</ol>
                <ol>Dr. en C. de la Computación</ol>
              </ul></p>
              <p><strong>Líneas de investigación y desarrollo</strong></p>
              <p><ul>
                <li>Machine Learning</li>
                <li>Deep Learning</li>
                <li>Visión Artificial y Procesamiento de Imágenes</li>
                <li>Inteligencia Computacional</li>
                <li>Algoritmos Evolutivos</li>
                <li>Metaheurísticas</li>
                <li>Modelado Matemático</li>
              </ul>
              <i>Todo ello primordialmente enfocado hacia aplicaciones en salud (e.g. análisis y diagnóstico automático de cáncer)</i>.</p>
              &nbsp;
              <p>Su productividad científica puede consultarse en:</p>
              
              <p><ul>
                <li><a href="https://scholar.google.com/citations?user=aUNONqIAAAAJ&hl=en" target="new">SCHOLAR</a></li>
                <li><a href="https://orcid.org/0000-0003-1818-4162" target="new">ORCID</a> </li>
                <li><a href="https://www.scopus.com/authid/detail.uri?authorId=23978536900" target="new">SCOPUS</a></li>
                </ul></p>
                &nbsp;
                <p><strong>Más información:</strong></p>
                <p>
                    <ul>
                        <ol><a href="https://g.co/kgs/HJSB1s" target="new">Perfil en Google</a></ol>
                        <ol>Email: <a href="mailto:arojasd@cic.ipn.mx">arojasd@cic.ipn.mx</a>, <a href="mailto:Alfonso.Rojas@gmail.com">Alfonso.Rojas@gmail.com</a></ol>
                    </ul>
                </p>
            </div>
    </div>
    <div className="col-lg-1"></div>
    </>
  );
};