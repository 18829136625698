import BackToTop from "react-back-to-top-button";
import { AiOutlineArrowUp } from "react-icons/ai";
import styles from "../css/HomePage.module.css";
import logoLab from "../images/logoLab.png";

export const Footer = () => {
  return (
    <>
      <BackToTop showOnScrollUp showAt={100} speed={100} easing="easeOutSine">
        <span className={styles.BackToTop}>
          <AiOutlineArrowUp style={{ color: "#fff", fontSize: 30 }} />
        </span>
      </BackToTop>
      <footer className={styles.footer}>
        <div>
          <img src={logoLab} alt="logo cic" />
        </div>
        <div>
          <h4>Centro de Investigación en Computación</h4>
          <p>Laboratorio de Robótica y Mecatrónica</p>
        </div>
        <div>
          
            <h5>Dirección</h5>
          
          <p>Av. Juan de Dios Bátiz, esq. Miguel Othón de Mendizábal,<br></br>{" "}
            Col. Nueva Industrial Vallejo, Alcaldía Gustavo A. Madero,<br></br>{" "}
            C.P. 07738, CDMX
          </p>
        </div>
      </footer>
    </>
  );
};
