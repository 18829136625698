import { profesores_data } from "../data/profesores_data";
import { useState, useEffect } from 'react';
import styles2 from "../css/GrupoTrabajo.module.css";
import d1 from "../images/personal_page_icon_2.png";
import { NavLink } from "../templates/NavLink";

/* Aquí se crean los cuadros de los grupos de trabajo*/

/* Se inicializa la lista*/
function ListaGrupoTrabajo() {

    /*Se inicia una lista de forma vacía */
    const [dataListaprofesores, setdataListaprofesores] = useState([]);/*se inicia una lista de forma vacía */

    /*Se colocan los datos en la lista*/
    useEffect(() => {
        setdataListaprofesores(profesores_data)
    }, [])

    /*Se comprueba si las listas tiene valores */
    if (dataListaprofesores.length === 0) {
        return <h1>No se encontró lista de Profesores</h1>
    }

    /* Aquí se modifica la forma que tendrán los cuadros*/
    return (
        <>
            <br />
            <div className={styles2.subtitulo}><h3>Profesores:</h3></div>
            <br />
            {
                dataListaprofesores.map((dato) => (/*se crea un cuadro por cada elemnto de la lista */
                       <div  class="col-md-12">
                        <div class="col-md-1"></div>
                       <div id={dato.Pagina}>
                            <div class="col-md-2">
                                <img src={dato.Foto}
                                    alt="Foto Persona del Grupo de Trabajo" width="100%"/>
                            </div>
                            <div class="col-md-2">
                                <p><strong>{dato.Nombre}</strong> </p>
                                <p>{dato.Nivel}</p>
                                <p><strong>Correo: </strong>{dato.Correo}</p>
                            </div>
                            <div class="col-md-1">
                            {dato.Link !== "" ? <a href={dato.Link} target="blank"><img source={d1}/></a> : ""}
                            {/*<p><a href={dato.Link} target="_blank">{dato.Link !== "" ? "Página personal" : ""}</a></p>*/}
                                <p>
                                <NavLink to={`/${dato.Pagina}`} >{dato.Pagina !== "" ? "Página personal" : ""}</NavLink>
                                </p>
                            </div>
                        </div>
                        
                        <div  class="col-md-6">
                        <div class="panel-group ficha-collapse">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                <h4 class="panel-title">
                                    {/*<a data-parent="#accordion" data-toggle="collapse" href={"#"+dato.ID} aria-expanded="false" aria-controls={dato.ID}>*/}
                                    Biografía
                                    {/*</a>*/}
                                </h4>
                                {/*<button type="button" class="collpase-button collapsed" data-parent="#accordion" data-toggle="collapse" href={"#"+dato.ID}></button>*/}
                                </div>
                                <div class="panel-collapse collapse in" id={dato.ID}>
                                <div class="panel-body">
                                    <h6>Educación</h6> {dato.Bio}
                                    <h6>{dato.Investigacion !== "" ? "Líneas de Investigación:" : ""}</h6> {dato.Investigacion}
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div><p>&nbsp;</p></div>
                    </div>
                    
                ))
            }
        </>
    );
}

export default ListaGrupoTrabajo;