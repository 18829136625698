
import styles from "../css/HomePage.module.css";
import styles2 from "../css/institucional.module.css";

import { useState } from "react";
import ListaGrupoTrabajo from "../components/ListGrupoTrabajo";
import EquipoTrabajo from "../images/Profesores.JPG";
import todos from "../images/Equipo5.JPG";
import { Banner_Lab } from "../templates/Banner_Lab";

import { VisitorCounter } from "../templates/VisitorCounter";



export const HomePage = () => {
  const [isMenuActive, setMenuActive] = useState(true);

  const handleMenu = () => {
    setMenuActive(isMenuActive ? false : true);
  }

  return (
    <>
      <Banner_Lab />
            
      <div className= {styles2.col_lg_2}></div>
      <div className= {styles2.col_lg_8}>
        <div align="center">
          <img src={todos} width="70%" alt="Foto Integrantes del Laboratorio de Robótica y Mecatrónica"/>
        </div>
      <div>
        <div>
          <h3>Objetivos del Laboratorio de Robótica y Mecatrónica<hr class={styles2.red}></hr></h3>
          <ul>
            <li>Generar nuevo conocimiento.</li>
            <li>Aplicar este conocimiento en la solución de problemas teóricos fundamentales.</li>
            <li>Transformar estos conocimientos en soluciones a problemas planteados por los sectores público y privado.</li>
          </ul>
        </div>
        <h3>Líneas de Investigación 
            <hr class={styles2.red}></hr>
        </h3>
        <div className={styles.homepageLineas}>
          <div>
            <h4>Líneas</h4>
            <ul>
              <li>Aprendizaje para máquinas</li>
              <li>Control clásico</li>
              <li>Control difuso</li>
              <li>Cómputo evolutivo</li>
              <li>Cómputo cuántico</li>
              <li>Modelado y control de robots</li>
              <li>Reconocimiento de patrones</li>
              <li>Redes neuronales y memorias asociativas</li>
              <li>Tratamiento y análisis digital de imágenes</li>
              <li>Sensores e instrumentación</li>
              <li>Óptica de adquisición de imagen</li>
            </ul>
          </div>
          <div>
            <h4>Aplicación</h4>
            <ul>
              <li>Robots móviles</li>
              <li>Drones</li>
              <li>Robots de servicio</li>
              <li>Drones</li>
              <li>Sensores</li>
              <li>Smart cities</li>
            </ul>
          </div>
        </div>
        <h3>Grupo de trabajo<hr class={styles2.red}></hr></h3>
        <div align= "center" class={styles2.col_lg_12}>
          <img src={EquipoTrabajo} width="100%" alt="Grupo de Trabajo del Laboratorio de Robótica y Mecatrónica"></img>
        </div>
        <div><ListaGrupoTrabajo /></div>
        
        <div> <VisitorCounter /> </div>
        
      </div>
      </div>
      <div></div>
    </>

  );
}