import React, { useEffect, useState } from 'react'
import styles from "../css/HomePage.module.css";
import logoLab from "../images/Equipo.JPG";
import equipoOctubre2024 from "../images/Equipo3.jpg";
import equipoNoviembre2024 from "../images/Equipo4.JPG";
import equipoFebrero2025 from "../images/Equipo5.JPG";
import examenElizabeth from "../images/Examen_Elizabeth.jpg";
import trabajando1 from "../images/Sesiones_Trabajo.png";
import entrevista from "../images/Galeria/Entrevista_16_01_2025.jpg";
import visita from "../images/Galeria/Visita.png";
import sem_02_14 from "../images/Galeria/Seminario_02_14.jpeg";
import sem_02_28 from "../images/Galeria/Seminario_02_28.jpeg";
import taquiza_feb_2025 from "../images/Galeria/Taquiza_Feb_2025.png";
import batiz from "../images/Galeria/Batiz.jpg"

import d1 from "../images/PresentationOpenLab/Diapositiva1.JPG";
import d2 from "../images/PresentationOpenLab/Diapositiva2.JPG";
import d4 from "../images/PresentationOpenLab/Diapositiva4.JPG";
import d6 from "../images/PresentationOpenLab/Diapositiva6.JPG";
import d8 from "../images/PresentationOpenLab/Diapositiva8.JPG";
import d10 from "../images/PresentationOpenLab/Diapositiva10.JPG";
import d12 from "../images/PresentationOpenLab/Diapositiva12.JPG";
import d14 from "../images/PresentationOpenLab/Diapositiva14.JPG";
import d16 from "../images/PresentationOpenLab/Diapositiva16.JPG";
import d18 from "../images/PresentationOpenLab/Diapositiva18.JPG";
import d20 from "../images/PresentationOpenLab/Diapositiva20.JPG";
import d22 from "../images/PresentationOpenLab/Diapositiva22.jpeg";


const Galeria = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {

    const slides = document.getElementsByClassName("mySlides");
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    const showSlides = () => {
      const slides = document.getElementsByClassName("mySlides");
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      setSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
      slides[slideIndex].style.display = "block";
    };

    const interval = setInterval(showSlides, 6000); // Cambiar la imagen cada 2 segundos (2000 milisegundos)

    return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte

  }, [slideIndex]);

  useEffect(() => {
    const slides = document.getElementsByClassName("mySlides");
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[slideIndex].style.display = "block";
  }, [slideIndex]);

  return (
    <>
      
      <div className="col-lg-12">
      <h1 style={{ textAlign: "center", margin: 30 }}>
        Galería
        <hr class="red"></hr>
      </h1>
      
      <div className={styles.galeriaContainer}>
        <img
          className={styles.galeriaImgMain}
          src={equipoFebrero2025}
          alt='Alumnos Laboratorio 24B'
        />
        <p className={styles.eventospageParrafo}>
          Laboratorio de Robótica y Mecatrónica (Febrero 2025)
        </p>
      </div>

      <div className={styles.galeriaContainer}>
        <img
          className={styles.galeriaImgMain}
          src={batiz}
          alt='Visita de Alumnos del CECyT 9'
        />
        <p className={styles.eventospageParrafo}>
          Visita de alumnos del CECyT #9 'Juan de Dios Bátiz'
        </p>
      </div>

      <div className={styles.galeriaContainer}>
        <img
          className={styles.galeriaImgMain}
          src={taquiza_feb_2025}
          alt='Alumnos Laboratorio 24B'
        />
        <p className={styles.eventospageParrafo}>
          Taquiza en el Laboratorio de Robótica y Mecatrónica (Febrero 2025)
        </p>
      </div>

      <div className={styles.galeriaContainer}>
        <img
          className={styles.galeriaImgMain}
          src={sem_02_28}
          alt='Seminario 28 Febrero 2025'
        />
        <p className={styles.eventospageParrafo}>
        Seminario Laboratorio 28 - febrero - 2025
        </p>
      </div>

      <div className={styles.galeriaContainer}>
        <img
          className={styles.galeriaImgMain}
          src={sem_02_14}
          alt='Seminario 14 Febrero 2025'
        />
        <p className={styles.eventospageParrafo}>
        Seminario Laboratorio 14 - febrero - 2025 
        </p>
      </div>

      <div className={styles.galeriaContainer}>
        <img
          className={styles.galeriaImgMain}
          src={visita}
          alt='Visita de alumnos del Instituto Universitario de Ciencias de la Educación de la Universidad Salesiana México'
        />
        <p className={styles.eventospageParrafo}>
        Visita de alumnos del Instituto Universitario de Ciencias de la Educación de la Universidad Salesiana México 
        </p>
      </div>


      <div className={styles.galeriaContainer}>
        <img
          className={styles.galeriaImgMain}
          src={entrevista}
          alt='Entrevista con Rocío Brauer para el programa XYZ'
        />
        <p className={styles.eventospageParrafo}>
        Entrevista con Rocío Brauer para el programa XYZ
        </p>
      </div>

      <div className={styles.galeriaContainer}>
        <img
          //className={styles.galeriaImgMain}
          src={trabajando1}
          alt='Sesiones de Trabajo'
        />
        <p className={styles.eventospageParrafo}>
          Sesiones de Trabajo de Proyecto SECTEI
        </p>
      </div>

      <div className={styles.galeriaContainer}>
        <img
          className={styles.galeriaImgMain}
          src={examenElizabeth}
          alt='Examen de Grado Dra. Elizabeth López Lozada'
        />
        <p className={styles.eventospageParrafo}>
          Examen de Grado Dra. Elizabeth López Lozada
        </p>
      </div>

      <div className={styles.galeriaContainer}>
        <img
          className={styles.galeriaImgMain}
          src={equipoNoviembre2024}
          alt='Alumnos Laboratorio 24B'
        />
        <p className={styles.eventospageParrafo}>
          Laboratorio de Robótica y Mecatrónica (Noviembre 2024)
        </p>
      </div>

      <div className={styles.galeriaContainer}>
        <img
          className={styles.galeriaImgMain}
          src={equipoOctubre2024}
          alt='Alumnos Laboratorio 24B'
        />
        <p className={styles.eventospageParrafo}>
          Laboratorio de Robótica y Mecatrónica (Octubre 2024)
        </p>
      </div>

      <div className={styles.galeriaContainer}>
        <img
          className={styles.galeriaImgMain}
          src={logoLab}
          alt='Alumnos Laboratorio 23A'
        />
        <p className={styles.eventospageParrafo}>
          Laboratorio de Robótica y Mecatrónica 23A
        </p>
      </div>
      <hr style={{ width: '65%', margin: '25px auto' }} />
      <div className={styles.flexContainer}>
        <div className={styles.flexGroup}>
          <div className="slideshow-container">
            <div>
              <div className="mySlides">
                <img src={d1} className={styles.galeriaImgSub} alt="Slide 1" />
              </div>
              <div className="mySlides">
                <img src={d2} className={styles.galeriaImgSub} alt="Slide 2" />
              </div>
              <div className="mySlides">
                <img src={d4} className={styles.galeriaImgSub} alt="Slide 4" />
              </div>
              <div className="mySlides">
                <img src={d6} className={styles.galeriaImgSub} alt="Slide 6" />
              </div>
              <div className="mySlides">
                <img src={d8} className={styles.galeriaImgSub} alt="Slide 8" />
              </div>
              <div className="mySlides">
                <img src={d10} className={styles.galeriaImgSub} alt="Slide 10" />
              </div>
              <div className="mySlides">
                <img src={d12} className={styles.galeriaImgSub} alt="Slide 12" />
              </div>
              <div className="mySlides">
                <img src={d14} className={styles.galeriaImgSub} alt="Slide 14" />
              </div>
              <div className="mySlides">
                <img src={d16} className={styles.galeriaImgSub} alt="Slide 16" />
              </div>
              <div className="mySlides">
                <img src={d18} className={styles.galeriaImgSub} alt="Slide 18" />
              </div>
              <div className="mySlides">
                <img src={d20} className={styles.galeriaImgSub} alt="Slide 20" />
              </div>
            </div>
            <div>
              <div className="mySlides">
                <img src={d1} className={styles.galeriaImgSub} alt="Slide 1" />
              </div>
              <div className="mySlides">
                <img src={d2} className={styles.galeriaImgSub} alt="Slide 2" />
              </div>
              <div className="mySlides">
                <img src={d4} className={styles.galeriaImgSub} alt="Slide 4" />
              </div>
              <div className="mySlides">
                <img src={d6} className={styles.galeriaImgSub} alt="Slide 6" />
              </div>
              <div className="mySlides">
                <img src={d8} className={styles.galeriaImgSub} alt="Slide 8" />
              </div>
              <div className="mySlides">
                <img src={d10} className={styles.galeriaImgSub} alt="Slide 10" />
              </div>
              <div className="mySlides">
                <img src={d12} className={styles.galeriaImgSub} alt="Slide 12" />
              </div>
              <div className="mySlides">
                <img src={d14} className={styles.galeriaImgSub} alt="Slide 14" />
              </div>
              <div className="mySlides">
                <img src={d16} className={styles.galeriaImgSub} alt="Slide 16" />
              </div>
              <div className="mySlides">
                <img src={d18} className={styles.galeriaImgSub} alt="Slide 18" />
              </div>
              <div className="mySlides">
                <img src={d20} className={styles.galeriaImgSub} alt="Slide 20" />
              </div>
            </div>
            <p className={styles.eventospageParrafo}>
              El Laboratorio de Robótica y Mecatrónica en el OpenLabs del CORE 2023
            </p>
          </div>
        </div>
        <div className={styles.flexGroup}>
          <div className="slideshow-container">
            <img src={d22} className={styles.galeriaImgSub2} alt="Slide 22" />
            <p className={styles.eventospageParrafo}>
              Equipo OpenLabs del CORE 2023
            </p>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default Galeria