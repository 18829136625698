import art1 from '../images/Banner_Images/art_CaderaDeEva.png';
import art2 from '../images/Banner_Images/articulo2024Medico.png';
import art3 from '../images/Banner_Images/Reconocimiento_CC.png'
import art4 from '../images/Banner_Images/Publicacion_dic_2024_1.png';
import art5 from '../images/Banner_Images/NuevaMaestria.png'
import art6 from '../images/Banner_Images/RevistaConversus.png'
import art7 from '../images/Banner_Images/OnceNoticias_AnalisisEmociones.png'

import server from '../images/Infra/servidores.jpg';
import banda from '../images/Infra/BandaTransportadora.jpg';
import brazo1 from '../images/Infra/BrazoMecanico.jpg';
import brazo2 from '../images/Infra/BrazoMecanico2.jpg'
import vehiculo1 from '../images/Infra/Vehiculo.jpeg';
import vehiculo2 from '../images/Infra/VehiculoGuia.jpg';

export const Publicaciones = () => {
    return (
      <>
        <h5 onLoad={window.scrollTo(0, 0)}>&nbsp;</h5>
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
            <h1>Medios publican acerca de LRyM</h1>
            <hr class="red"/>
            <div align="center" className="col-lg-12">

              <div>
                <br /><h4>Once Noticias</h4> 
                  <image>
                      <a href='https://www.facebook.com/watch/?v=644493334805920' target='new'><img src={art7} width="80%" alt="Artículo Canal Once"/></a>
                  </image>
                </div>

                <div>
                <br /><h4>La Cadera de Eva</h4> 
                  <image>
                      <a href='https://lacaderadeeva.com/entrevistas/3-cientificas-del-politecnico-que-debes-conocer/12630' target='new'><img src={art1} width="80%" alt="Artículo de la cadera de Eva"/></a>
                  </image>
                </div>

                <div>
                <br />
                <h4>Gaceta Politécnica</h4> 
                  <image>
                      <a href="https://www.ipn.mx/assets/files/imageninstitucional/docs/gaceta-seleccion/2024/12/g-180.pdf" target='new'><img src={art2} width="80%" alt="Artículo Gaceta Politécnica"/></a>
                  </image>
                </div>

                <div>
                <br />
                <h4>Gaceta Politécnica</h4> 
                  <image>
                      <a href='https://www.ipn.mx/assets/files/imageninstitucional/docs/gaceta-seleccion/2024/11/g-179.pdf' target='new'><img src={art3} width="80%" alt="Artículo Gaceta Politécnica"/></a>
                  </image>
                </div>

                <div>
                <br />
                <h4>Once Noticias</h4> 
                  <image>
                      <a href='https://www.facebook.com/watch/?v=936379217999351' target='new'><img src={art5} width="80%" alt="Artículo Once Noticias"/></a>
                  </image>
                </div>

                <div>
                <br />
                <h4>Once Noticias</h4> 
                  <image>
                      <a href='https://oncenoticias.digital/agenda-ipn/robots-con-ia-asumiran-labores-riesgosas-investigador-del-ipn/421590/amp/?fbclid=IwY2xjawHNYllleHRuA2FlbQIxMQABHcXPPvEmDr36FeBKjcRXYSWyBJ3Jv_ZENJqIyMEhEnvAkyJokkBIpIdsKA_aem_OlgN3N6OQ0z3GyoukCiz1g' target='new'><img src={art4} width="80%" alt="Artículo Once Noticias"/></a>
                  </image>
                </div>

                <div>
                <br />
                <h4>Revista Conversus</h4> 
                  <image>
                      <a href='https://drive.google.com/file/d/16KK9GsB_Lp_0-uG1qcE9H82sKpHZ0ccN/view?fbclid=IwY2xjawF1lzRleHRuA2FlbQIxMQABHancPQc5MS5_n4ATEorgd9Gpf48Ey_PDJCKPX9MsTa6EV5NewoTnXNV4tQ_aem_gt4hoc6tcrkUGF4AqFaZgQ&sfnsn=scwspmo' target='new'><img src={art6} width="80%" alt="Artículo Once Noticias"/></a>
                  </image>
                </div>
            </div>
        </div>
        <div className="col-lg-2"></div>
      </>
  );
}