import introduccion from "../images/SeminarioMetodolo/Seminario_Introduccion.png";

export const SeminarioMetodolo = () => {
  return (
    <>
      <div class="col-md-1"  onLoad={window.scrollTo(0, 0)}></div>
      <div class="col-md-10">
        <h1>Seminario de Metodología de la Investigación</h1>
        <hr class="red"></hr>
        <div align= "center">
            <strong>Sesión 1 - Presentación del Seminario </strong> - 6/marzo/2025 <br /><br />
        <a href="https://www.youtube.com/watch?v=sfuTzmf_HwE" target="new">
        <img
          src={introduccion}
          alt='Imagen del video de la Sesión del Seminario'
          width="35%"
        />
        <br />Ver Video </a>
        </div>
      </div>
    </>
  );
};
