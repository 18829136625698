export const propuestas_tesis = [
  {
    ID: 1,
    Nombre: "Dr. Juan Humberto Sossa Azuela",
    Correo: "humbertosossa@gmail.com",
    Tesis: [],
  },
  {
    ID: 2,
    Nombre: "Dra. Elsa Rubio Espino",
    Correo: "erubio@cic.ipn.mx",
    Tesis: [],
  },
  {
    ID: 3,
    Nombre: "Dr. Erik Zamora",
    Correo: "ezamora1981@gmail.com",
    Tesis: [],
  },
  {
    ID: 4,
    Nombre: "Dr. Jesús Yaljá Montiel Pérez",
    Correo: "yalja@ipn.mx",
    Tesis: [
      {
        Nivel:"Maestría y doctorado",
        Tema: "Predicción de series de tiempo caóticas",
        Descripcion: "Las series de tiempo caóticas pueden ser datos financieros, de signos vitales médicos, etc. En cualquier caso deben de ser analizadas, procesadas y el problema a resolver es predecir los estados futuros de dichas señales usando redes neuronales recurrentes o arquitecturas híbridas.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Caracterización de comportamiento humano en zonas limitadas",
        Descripcion: "Con base a la adquisición de caracteristicas de comportamiento con RN se crean patrones de que se asocia a individuos para poder usarse en aplicaciones de  ciudades inteligentes.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Predicción de series de tiempo caóticas",
        Descripcion: "Los estados energéticos de las moleculas simples que tienen un comportamiento cuántico y se pueden modelar mediante algoritmos de la cómputación cuántica.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Predicción de series de tiempo caóticas",
        Descripcion: "Proyectos relacionados a robots con aplicaciones de servicio cómo la vigilancia, limpieza, monitorización etc. Así cómo robotica para servicio en el ámbito médico como la extracción de sangre, cosido de heridas, etc.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Predicción de series de tiempo caóticas",
        Descripcion: "Proyectos donde los algoritmos se implementen en un arreglo para FPGAs y se realice procesamiento en hardware. como lo puede ser reconocimiento de objetos en video, clasificación, predicción etc.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Predicción de series de tiempo caóticas",
        Descripcion: "Implementación de algoritmos cuánticos para predicción en series de tiempo, reconocimient y clasificación en imágenes y señales, etc. Procesos de reacciones químicas simples.",
      }

    ],
  },
  {
    ID: 5,
    Nombre: "Dr. Jesús Yaljá Montiel Pérez en colaboración con Dr. Alberto Maldonado Romo",
    Correo: "yalja@ipn.mx",
    Tesis: [
      {
        Nivel:"Maestría y doctorado",
        Tema: "Quantum autoencoder",
        Descripcion: "Generar un autoencoder para encontrar una mayor codificación en los datos para redes neuronales cuánticas.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Quantum dynamic circuit on Oracles",
        Descripcion: "Proponer equivalencia de casos de uso en la construcción de oráculos basados en mediciones intermedias, para optimizar circuitos en un hardware real.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "QGAN/ Quantum Boltzmann Machine",
        Descripcion: "Generar distribuciones de datos y distribuciones no unitarias, para producir distribuciones de probabilidad.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Mitigación de errores usando Dynamic Circuit",
        Descripcion: "Enfocarse en la construcción de casos de algoritmos cuánticos p; aplicar métodos de mitigación de Error.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Uso de Tensor Networks para representaciones de clasificación multiclase",
        Descripcion: "Tensor networks como estructura para datos y usar una heurística para encontrar la reducción del circuito basado en los bloques de los circuitos cuánticos.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Implementar protocolo de envío de datos seguro mediante la Quantum-Teleportation",
        Descripcion: "Generar protocolo para envío de datos y prevenir mediante las arquitecturas del hardware el envío de datos seguros tomando como base le protocolo BB84.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Resolver problemas QUBO y PUBO con QAOA",
        Descripcion: "Realizar le caos de uso de QAOA con heurísticas para la reducción del espacio e identificar con mayor probabilidad los estados óptimos. Problema de la mochila a tratar.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Qkmeans y QKNN",
        Descripcion: "Generar un circuito cuántico que busque los 'k' más cercanos, utilizando el algoritmo de Grover y mediciones intermedias y QROM.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Resolver sistema de ecuaciones lineales con algoritmos cuánticos en Hardware",
        Descripcion: "Usando el algoritmo HHL e implementarlo en hardware de IBM.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Representación de operadores morfológicos en imágenes como vectores de estados",
        Descripcion: "Representar imágenes en un vector de estado para poder aplicar operadores morfológicos y filtrar los objetos con la mayor probabilidad asociada con respecto a su parte clásica.",
      },
    ],
  },
  {
    ID: 6,
    Nombre: "Dr. Ponciano Jorge Escamilla Ambrosio",
    Correo: "pescamillaa@ipn.mx",
    Tesis: [],
  },
  {
    ID: 7,
    Nombre: "Dr. Tat'y Muata Velu",
    Correo: "tmwata@cic.ipn.mx",
    Tesis: [],
  }
];
